<template>
  <VResponsive
    :aspect-ratio="aspectRatio"
    :min-height="156"
  >
    <video
      v-if="typeCode === 'file'"
      :src="body"
      controls
      preload="metadata"
    />
    <div
      v-else
      v-html="body"
    />
  </VResponsive>
</template>

<script>
export default {
  name: 'UVideo',
  props: {
    typeCode: {
      type: String,
      default: ''
    },
    body: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      aspectRatio: 16 / 9
    }
  }
}
</script>
