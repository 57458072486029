<template>
  <div
    v-if="preparedStatus"
    class="step-status"
    :title="$t(preparedStatus.title)"
  >
    <VAvatar
      :class="avatarClasses"
      size="32"
    >
      <VIcon
        v-if="preparedStatus.icon"
        class="step-status__icon"
      >
        {{ preparedStatus.icon }}
      </VIcon>
    </VAvatar>

    <slot>
      <span v-if="!onlyIcon">{{ $t(preparedStatus.title) }}</span>
    </slot>
  </div>
</template>

<script>
import { prepareStepStatus } from '@/utils/status'

export default {
  name: 'StepStatus',

  props: {
    status: {
      type: Object,
      required: true
    },

    onlyIcon: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    avatarClasses () {
      return ['step-status__avatar', this.preparedStatus?.classNames].filter(Boolean)
    },

    preparedStatus () {
      if (this.disabled) {
        return prepareStepStatus('disabled')
      }

      const code = this.status?.code
      return prepareStepStatus(code)
    }
  }
}
</script>

<style lang="scss">
  .step-status {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-transform: uppercase;
    font-size: 1rem;
  }

  .step-status__avatar {
    border: 2px solid currentColor;
    background-color: #fff;
    color: map-deep-get($material-light, 'text', 'disabled');
  }

  .step-status__icon.step-status__icon {
    color: inherit;
  }
</style>
