<template>
  <Component
    :is="component"
    v-bind="{...props}"
    @keydown.esc="toggleView(false)"
  >
    <VCard
      v-if="!loading"
      class="training"
      flat
    >
      <TrainingSteps
        :is-hidden-next-button="isHiddenNextButton"
        :is-last-lesson="isLastLesson"
        @next="next"
      />

      <div class="training__body">
        <template v-if="!stepLoading">
          <Component
            :is="`training-${step.lesson_type_info.code}`"
            :key="step.id"
            class="step"
            :is-last-lesson="isLastLesson"
            @show-dialog="showDialog"
          />

          <!-- Добавим компонент для превью изображений -->
          <ImagePreview />

          <footer
            v-if="$vuetify.breakpoint.smAndDown && session.steps.length > 1"
            class="training-navigation"
          >
            <VBtn
              color="success"
              :small="$vuetify.breakpoint.xs"
              :disabled="isFirstLesson"
              @click.prevent="previous"
            >
              <VIcon left>
                navigate_before
              </VIcon>
              {{ $t('previous_lesson.one') }}
            </VBtn>

            <template v-if="session.is_active && !session.is_completed">
              <SessionAcceptForm
                v-if="isLastLesson"
                key="accept-form"
                :session="session"
              />

              <VBtn
                v-else
                color="success"
                :small="$vuetify.breakpoint.xs"
                :disabled="isHiddenNextButton"
                @click="next"
              >
                {{ $t('next_lesson.one') }}
                <VIcon right>
                  navigate_next
                </VIcon>
              </VBtn>
            </template>

            <VBtn
              v-else
              color="success"
              :small="$vuetify.breakpoint.xs"
              :disabled="isLastLesson"
              @click="next"
            >
              {{ $t('next_lesson.one') }}
              <VIcon right>
                navigate_next
              </VIcon>
            </VBtn>
          </footer>

          <VFabTransition>
            <VBtn
              v-if="$vuetify.breakpoint.smAndDown"
              :title="$t('$app.expand_list_of_lessons')"
              fab
              color="primary"
              fixed
              right
              bottom
              @click="toggleSteps(!isOpenSteps)"
            >
              <VIcon>list</VIcon>
            </VBtn>
          </VFabTransition>
        </template>

        <VProgressCircular
          v-else
          :size="50"
          color="primary"
          indeterminate
          class="app-loader"
        />
      </div>
    </VCard>
    <VProgressCircular
      v-else
      :size="50"
      color="primary"
      indeterminate
      class="app-loader"
    />
    <VDialog
      v-model="dialog.active"
      width="640"
    >
      <VCard>
        <VCardTitle class="headline flex-nowrap">
          <div v-html="dialog.title" />
          <VSpacer />
          <VBtn
            :title="$t('close.one')"
            icon
            @click="dialog.active=false"
          >
            <VIcon>close</VIcon>
          </VBtn>
        </VCardTitle>
        <VDivider />
        <VCardText class="training__dialog body-1">
          <div v-html="dialog.body" />
        </VCardText>
        <VDivider />
        <VCardActions>
          <VSpacer />
          <VBtn
            title="Вернуться к уроку"
            text
            color="primary"
            @click="dialog.active=false"
          >
            Вернуться к уроку
          </VBtn>
          <VBtn
            v-if="!dialog.hideContinueButton"
            :title="$t('continue.one')"
            color="success"
            @click="next"
          >
            {{ $t('continue.one') }}
          </VBtn>
        </VCardActions>
      </VCard>
    </VDialog>
    <SessionAcceptForm
      v-if="acceptFormShown"
      key="accept-form-sm"
      form-shown
      :session="session"
    />
  </Component>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { VDialog } from 'vuetify/lib'

import { getNextStep, getStepAttempt, getPreviousStep, getStep } from '@/api/api'
import documentTitle from '@/mixins/documentTitle'
import { H5PAPI } from '@/utils/scorm'
import { UDate } from '@/utils/date'
import { disableMediaDownloads, showHiddenIframes } from '@/utils/common'
import SessionAcceptForm from '@components/SessionAcceptForm.vue'
import TrainingEssay from '@components/TrainingEssay.vue'
import TrainingLecture from '@components/TrainingLecture.vue'
import TrainingQuiz from '@components/TrainingQuiz.vue'
import TrainingScorm from '@components/TrainingScorm.vue'
import ImagePreview from '@components/ImagePreview.vue'
import TrainingSteps from '@components/TrainingSteps.vue'
import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'
import '@/assets/fonts/katex/index.css'
import '@/assets/fonts/font-awesome/index.css'

export default {
  name: 'TheTraining',

  components: {
    TrainingEssay,
    TrainingLecture,
    TrainingQuiz,
    TrainingScorm,
    ImagePreview,
    TrainingSteps,
    SessionAcceptForm,
    VDialog
  },
  mixins: [documentTitle],

  data () {
    return {
      loading: true,
      stepLoading: false,
      h5p: null,
      observers: [],
      dialog: {
        active: false,
        title: '',
        body: '',
        hideContinueButton: false
      },
      acceptFormShown: false
    }
  },

  computed: {
    ...mapGetters({
      vendor: getters.VENDOR,
      account: getters.ACCOUNT,
      session: getters.SESSION,
      step: getters.STEP,
      isOpenSteps: getters.IS_OPEN_STEPS,
      isFullView: getters.IS_FULL_VIEW
    }),

    vendorCopyProtectionEnabled () {
      return this.vendor?.branding?.copy_protection_enabled || false
    },

    component () {
      return this.isFullView ? 'v-dialog' : 'div'
    },

    props () {
      if (this.component === 'v-dialog') {
        return {
          fullscreen: true,
          value: true
        }
      }

      return {}
    },

    currentLessonIdx () {
      return this.session.steps.findIndex(step => step.id === this.step.id)
    },

    isFirstLesson () {
      return this.currentLessonIdx === 0
    },

    isLastLesson () {
      return this.currentLessonIdx === (parseInt(this.session.steps.length, 10) - 1)
    },

    requiredLessonsIsCompleted () {
      return (this.session?.steps || []).every(step => (step.is_required && step.is_completed) || !step.is_required)
    },

    isHiddenNextButton () {
      const step = this.step
      const isQuiz = step.lesson_type_info?.code === 'quiz'
      const isEssay = step.lesson_type_info?.code === 'essay'
      const isScorm = step.lesson_type_info?.code === 'scorm'

      if (isQuiz || isEssay || isScorm) {
        if (step.is_required && !step.is_accepted && (!step.is_completed || !step.settings.deferrable)) {
          return true
        }
      }

      return false
    }
  },

  watch: {
    $route: {
      handler: async function (to, from) {
        const isNeedToUpdated = () => (
          parseInt(to.params.stepID, 10) !== parseInt(from.params.stepID, 10) ||
          parseInt(to.params.sessionID, 10) !== parseInt(from.params.sessionID, 10) ||
          (parseInt(to.query.attempt, 10) && parseInt(to.query.attempt, 10) !== parseInt(from.query.attempt, 10))
        )

        if (isNeedToUpdated()) {
          this.stepLoading = true

          try {
            const attempt = to.query.attempt
            const response = attempt
              ? await getStepAttempt(to.params.sessionID, to.params.stepID, attempt)
              : await getStep(to.params.sessionID, to.params.stepID)

            const { session } = response
            if (!session || (!session.is_active && session.order_status.code !== 'training_complete')) {
              throw new Error(`Учебная сессия ${session.id} в данный момент недоступна`)
            }

            /* Обновим данные шага в списке шагов сессии (возможно они не обновились сервере) */
            const step = (response.session?.steps || []).find(step => step.id === response.step?.id)
            if (step) {
              Object.assign(step, response.step)
            }

            this.updateStep(response)
            this.documentTitle = response.session.course_title
            this.stepLoading = false
          } catch (err) {
            this.$router.replace({ name: 'session', params: { sessionID: to.params.sessionID } }).catch(() => {})
          }
        }
      }
    }
  },

  created () {
    this.register()
  },

  beforeDestroy () {
    this.unregister()
  },

  methods: {
    ...mapActions({
      updateStep: actions.UPDATE_STEP,
      toggleSteps: actions.TOGGLE_STEPS,
      toggleView: actions.TOGGLE_VIEW,
      saveScorm: actions.SAVE_SCORM
    }),

    formatDate: UDate.formatDate,

    async next () {
      const method = this.session.is_active ? 'POST' : 'GET'
      this.stepLoading = true
      const response = await getNextStep(this.session.id, this.step.id, method)
      const path = { name: 'session', params: { sessionID: response.session.id } }

      if (response?.step?.id) {
        path.name = 'training'
        path.params.stepID = response.step.id
      }

      this.$router.push(path).catch(() => {})
      this.stepLoading = false
      this.dialog.active = false
      this.acceptFormShown = false
    },

    async previous () {
      this.stepLoading = true
      const response = await getPreviousStep(this.session.id, this.step.id)

      const path = {
        name: 'training',
        params: { sessionID: response.session.id, stepID: response.step.id }
      }

      this.$router.push(path).catch(() => {})
      this.stepLoading = false
    },

    async onmessage (evt) {
      const isScorm = this.step?.lesson_type_info?.code === 'scorm'

      if (!isScorm) {
        return
      }

      const action = evt.data.action.toLowerCase()
      switch (action) {
        case 'complete':
        case 'completed':
        case 'finish':
        case 'finished':
        case 'pass':
        case 'passed': {
          if (this.step.is_accepted) {
            const dialog = {}
            dialog.title = `Урок завершён.${this.isLastLesson ? '' : ' Перейти к следующему?'}`
            dialog.body = `Вы выполнили урок «${this.step?.lesson_title}».<br>
                           Ваша оценка: <span class="success--text">${this.step?.grade}</span><br>
                           Вы можете повторно изучить материал${this.isLastLesson ? '' : ' или перейти к следующему уроку'}.`
            dialog.hideContinueButton = this.isLastLesson
            this.showDialog(dialog)
            return
          }

          // eslint-disable-next-line
          console.log('h5p: обучение успешно завершено')

          // Приведём полученный балл к интервалу [0..100], т. е. к проценту выполнения.
          const grade = Math.max(0, parseInt(evt.data.score) || 0)
          const min = Math.max(0, parseInt(evt.data.minScore) || 0)
          const max = Math.max(0, parseInt(evt.data.maxScore) || 0)
          let gradeRatio = (grade > 0) ? 100 : 0
          if (max > min) {
            gradeRatio = Math.max(0, Math.min(100, Math.round(100.0 * ((1.0 * grade) / (max - min)))))
          }

          const grades = {
            grade: gradeRatio,
            raw: grade,
            min,
            max
          }

          const { step } = await this.saveScorm({
            stepID: this.step.id,
            grades,
            accepted: true
          })

          const dialog = {}
          dialog.title = `Урок завершён.${this.isLastLesson ? '' : ' Перейти к следующему?'}`
          dialog.body = `Вы выполнили урок <span class="success--text">${step?.lesson_title}</span><br>
                         Ваша оценка: <span class="success--text">${step?.grade}</span><br>
                         Вы можете повторно изучить материал${this.isLastLesson ? '' : ' или перейти к следующему уроку'}.`
          dialog.hideContinueButton = this.isLastLesson
          this.showDialog(dialog)
          break
        }

        default: {
          break
        }
      }
    },

    showDialog (dialog) {
      if (this.requiredLessonsIsCompleted) {
        this.acceptFormShown = true
      } else {
        this.dialog.title = dialog.title
        this.dialog.body = dialog.body
        this.dialog.active = true
        this.dialog.hideContinueButton = !!dialog.hideContinueButton
      }
    },

    async register () {
      const to = this.$route
      this.h5p = new H5PAPI(this.onmessage)

      this.observers.push(showHiddenIframes())
      if (this.vendorCopyProtectionEnabled) {
        this.observers.push(disableMediaDownloads())
      }

      this.observers.forEach(observer => observer.observe(
        document.body,
        { childList: true, subtree: true }
      ))

      try {
        const attempt = to.query.attempt
        const response = attempt
          ? await getStepAttempt(to.params.sessionID, to.params.stepID, attempt)
          : await getStep(to.params.sessionID, to.params.stepID)

        const { session } = response
        if (!session || (!session.is_active && session.order_status.code !== 'training_complete')) {
          throw new Error(`Учебная сессия ${session.id} в данный момент недоступна`)
        }

        this.updateStep(response)
        this.documentTitle = session.course_title
        this.loading = false

        // Поймаем и обработаем сообщения h5p-виджетов.
        this.$nextTick(() => {
          this.h5p.attach(window)
        })
      } catch (err) {
        this.$router.replace({ name: 'session', params: { sessionID: to.params.sessionID } }).catch(() => {})
      }
    },

    unregister () {
      this.h5p.destroy(window)
      this.observers.forEach(observer => observer.disconnect())
      this.observers = []
    }
  }
}
</script>

<style lang="scss">
.lesson-title {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: $spacer * 6;
  font-size: 1.875rem;
  line-height: 1.2;
}

.training {
  display: flex;
  min-height: 100%;

  &.theme--light {
    background-color: #f5f5f5;
  }
}

.v-dialog .v-card .training__dialog {
  padding-top: 1rem;
  line-height: 2rem;
}

.training__body {
  position: relative;
  flex-grow: 1;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  flex-flow: column nowrap;
  min-width: 0;
  max-width: 100%;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.v-dialog--fullscreen {
  .training-steps {
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      top: 0;
      position: sticky;
      height: 100vh;
      flex-shrink: 0;
      overflow-y: auto;
      border-top-right-radius: $border-radius-root;
      border-bottom-right-radius: $border-radius-root;
    }
  }
}

.step {
  &.v-card {
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    overflow: hidden;
  }

  &.step-essay,
  &.step-quiz {
    .step__header,
    .step__body {
      margin-bottom: 1rem;
    }
  }
}

.step__body {
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;

  .v-window-item {
    overflow: auto;
  }
}

.task {
  color: map-deep-get($material-light, "text", "primary");
}

.task__header {
  display: flex;
  flex-direction: column;
  background-color: rgba(black, 0.04);

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    flex-direction: row;
  }
}

.task__index {
  margin-right: 0.5rem;
  flex: 0 0 auto;
}

.task__scores {
  white-space: nowrap;
  text-align: right;
  align-self: stretch;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  color: #fff;
  padding: 0.5rem 1rem;
  text-transform: uppercase;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    order: 1;
  }

  &[data-grade] {
    background-color: var(--v-warning-base);
  }

  &[data-grade="0"],
  &[data-grade^="-"] {
    background-color: var(--v-error-base);
  }

  &[data-grade="100"] {
    background-color: var(--v-success-base);
  }
}

.task__title {
  padding: 0.5rem 1rem;
  display: flex;
  flex: 1 1 auto;
}

.task__inner {
  display: grid;
  align-items: start;
  gap: 1rem;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: 1fr 1fr;
  }
}

.task__answers.task__answers {
  list-style: none;
  margin: 0;
  padding: 0;
}

.training-navigation {
  display: flex;
  justify-content: center;
  padding-top: $spacer * 4;
  background-color: map-get($material-light, 'background');

  > .v-btn {
    margin-left: $spacer;
    margin-right: $spacer;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      margin-left: $spacer * 2;
      margin-right: $spacer * 2;
    }
  }
}
</style>
