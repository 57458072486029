<template>
  <VCard
    outlined
    :class="classes"
  >
    <header class="task__header">
      <aside
        v-if="isReadonly"
        class="task__scores"
        :data-grade="grade"
      >
        {{ $t('scores_for_question.one') }}: {{ grade }}
      </aside>

      <div class="task__title">
        <VChip
          label
          small
          class="task__index"
        >
          {{ index + 1 }}
        </VChip>

        <div
          v-if="question.question"
          class="rte-content bvi-speech"
          v-html="question.question"
        />
        <VAlert
          v-else
          dense
        >
          {{ $t('$app.no_question_text') }}
          <RouterLink :to="{ name: 'messenger' }">
            {{ $t('$app.need_help') }}
          </RouterLink>?
        </VAlert>
      </div>
    </header>

    <VCardText
      v-if="answers.length"
      class="text--primary"
    >
      <Component
        :is="`training-quiz-question-type-${type}`"
        v-if="!isHideAcceptedContent"
        :value="value"
        :answers="answers"
        :is-readonly="isReadonly"
        :is-revealed="isRevealed"
        :is-show-correct-answer="isShowCorrectAnswer"
        :is-multiple-choice="question.multiple_choice"
        :available-answers="question.available_answers_values"
        @input="onChange"
      />
      <p
        v-else
        class="text-center"
      >
        Варианты ответа скрыты
      </p>
    </VCardText>

    <VAlert
      v-else
      dense
    >
      {{ $t('$app.no_answer_option') }}
      <RouterLink :to="{ name: 'messenger' }">
        {{ $t('$app.need_help') }}
      </RouterLink>?
    </VAlert>

    <VCardText v-if="isRevealed && question.comments">
      <UComment>
        <MarkdownContent :content="question.comments" />
      </UComment>
    </VCardText>

    <VAlert
      v-if="isReadonly && !isRankingType && !isMappingType && question.strict_grading && grade < 100"
      class="mb-0"
      dense
      outlined
      type="error"
    >
      Выбраны не&nbsp;все правильные варианты ответа.
    </VAlert>
  </VCard>
</template>

<script>
import TrainingQuizQuestionTypeSimple from '@/components/TrainingQuizQuestionTypeSimple.vue'
import TrainingQuizQuestionTypeRanking from '@/components/TrainingQuizQuestionTypeRanking.vue'
import TrainingQuizQuestionTypeMapping from '@/components/TrainingQuizQuestionTypeMapping.vue'
import UComment from '@components/UComment.vue'
import MarkdownContent from '@/components/MarkdownContent.vue'
import { Answer, AnswerSettings, QuestionAnswer } from '@/utils/quiz'

export default {
  name: 'TrainingQuizQuestion',

  components: {
    TrainingQuizQuestionTypeSimple,
    TrainingQuizQuestionTypeRanking,
    TrainingQuizQuestionTypeMapping,
    UComment,
    MarkdownContent
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },

    question: {
      type: Object,
      required: true
    },

    userAnswers: {
      type: Array,
      default: () => []
    },

    isExercise: {
      type: Boolean,
      default: false
    },

    isReadonly: {
      type: Boolean,
      default: false
    },

    isShowCorrectAnswer: {
      type: Boolean,
      default: false
    },

    isAcceptIncorrectAnswers: {
      type: Boolean,
      default: false
    },

    isHideAcceptedContent: {
      type: Boolean,
      default: false
    },

    index: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      isRevealed: false
    }
  },

  computed: {
    grade () {
      const MAX_GRADE_VALUE = 100
      const MIN_GRADE_VALUE = 0
      const grade = Math.max(parseInt(this.question.grade), MIN_GRADE_VALUE)

      if (this.isRankingType || this.isMappingType) {
        return grade < MAX_GRADE_VALUE ? MIN_GRADE_VALUE : MAX_GRADE_VALUE
      }

      return grade
    },

    type () {
      return this.question.type?.code || AnswerSettings.DEFAULT_QUESTION_TYPE
    },

    isMappingType () {
      return this.type === AnswerSettings.QUESTION_TYPE_MAPPING
    },

    isRankingType () {
      return this.type === AnswerSettings.QUESTION_TYPE_RANKING
    },

    formattedUserAnswers () {
      return this.userAnswers.map(answer => new Answer(answer))
    },

    answers () {
      let answers = this.question.answers
      if ((this.isRankingType || this.isMappingType) && this.userAnswers.length) {
        answers = this.userAnswers || answers
      }

      return (answers || []).map(answer => new QuestionAnswer(answer, this.userAnswers))
    },

    classes () {
      return {
        task: true,
        'task--readonly': this.isReadonly
      }
    }
  },

  created () {
    this.register()
  },

  methods: {
    onChange (evt) {
      this.$emit('input', evt)

      if (this.isExercise && !this.isAcceptIncorrectAnswers) {
        // Тест тренировочный. Подсветим правильные варианты ответа и покажем комментарии.
        this.isRevealed = true
      }
    },

    register () {
      if (this.isReadonly && this.isShowCorrectAnswer) {
        // Подсветим правильные варианты ответа и покажем комментарии после завершения теста.
        this.isRevealed = true
        return
      }

      if (this.isMappingType && this.userAnswers?.length) {
        this.$emit('input', (this.answers || []).map(answer => `${answer.id},${answer.settings.userAnswerId}`))
        return
      }

      let answers = this.formattedUserAnswers
      if (this.isRankingType) {
        answers = this.answers
      }

      this.$emit('input', (answers || []).map(answer => answer.id))
    }
  }
}
</script>
