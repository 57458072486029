export const accept = `
  .xml, application/xml,
  .pdf, application/pdf,
  .doc,  application/vnd.ms-word, application/msword,
  .docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document,
  .xls,  application/vnd.ms-excel, application/msexcel,
  .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
  .ppt,  application/vnd.ms-powerpoint, application/mspowerpoint,
  .pptx, application/vnd.openxmlformats-officedocument.presentationml.presentation,
  .ppsx, application/vnd.openxmlformats-officedocument.presentationml.slideshow,
  .sldx, application/vnd.openxmlformats-officedocument.presentationml.slide,
  .odt, .fodt, application/vnd.oasis.opendocument.text,
  .odf, .fodf, application/vnd.oasis.opendocument.formula,
  .ods, .fods, application/vnd.oasis.opendocument.spreadsheet,
  .odp, .fodp, application/vnd.oasis.opendocument.presentation,
  .odg, .fodg, application/vnd.oasis.opendocument.graphics,
  .odc, .fodc, application/vnd.oasis.opendocument.chart,
  .odi, .fodi, application/vnd.oasis.opendocument.image,
  .kml, .kmz, application/vnd.google-earth.kml+xml, application/vnd.google-earth.kmz,
  text/*, image/*, audio/*, video/*
`
