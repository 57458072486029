<template>
  <div v-if="supported">
    <VBtn
      icon
      @click="dialog=true"
    >
      <VIcon>mic</VIcon>
    </VBtn>
    <VDialog
      v-model="dialog"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      width="480"
    >
      <VCard>
        <VCardTitle class="headline">
          {{ $t('$app.audio_recorder.title') }}
          <VSpacer />
          <VBtn
            :title="$t('close.one')"
            icon
            @click="close"
          >
            <VIcon>close</VIcon>
          </VBtn>
        </VCardTitle>
        <VDivider />
        <template v-if="url">
          <VCardText>
            <audio
              class="mt-8"
              controls
              :src="url"
            >
              <a
                :href="url"
                download
              >{{ file.name }}</a>
            </audio>
          </VCardText>
          <VDivider />
          <VCardActions>
            <VBtn
              :title="$t('delete.one')"
              color="error"
              @click="reset"
            >
              {{ $t('delete.one') }}
            </VBtn>
            <VBtn
              :title="$t('save.one')"
              color="success"
              @click="save"
            >
              {{ $t('save.one') }}
            </VBtn>
          </VCardActions>
        </template>
        <VCardActions
          v-else
          class="py-8"
        >
          <VSpacer />
          <VBtn
            v-if="recording"
            class="pulse"
            :title="$t('$app.audio_recorder.start')"
            color="error"
            fab
            @click="stop"
          >
            <VIcon>stop</VIcon>
          </VBtn>
          <VBtn
            v-else
            :title="$t('$app.audio_recorder.start')"
            fab
            color="primary"
            @click="start"
          >
            <VIcon>play_arrow</VIcon>
          </VBtn>
          <VSpacer />
        </VCardActions>
        <VCardText v-if="recording">
          <div class="text-center">
            {{ $t('$app.audio_recorder.progress') }}
          </div>
        </VCardText>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import audioFileIcon from '@/assets/images/audio-file-icon.svg'
import * as actions from '@/store/actions/types'

function findMic () {
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {}
  }

  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function (constraints) {
      const getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia

      if (!getUserMedia) {
        return Promise.reject(new Error(this.$t('$app.audio_recorder.error')))
      }

      return new Promise(function (resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject)
      })
    }
  }
}

export default {
  name: 'AudioRecorder',
  data () {
    return {
      chunks: [],
      recorder: null,
      stream: null,
      recording: false,
      file: null,
      url: null,
      supported: true,
      dialog: false
    }
  },

  created () {
    this.register()
  },

  beforeDestroy () {
    this.unregister()
  },

  methods: {
    ...mapActions({
      createNotification: actions.CREATE_NOTIFICATION
    }),

    handleChunks () {
      if (this.chunks && this.chunks.length) {
        const suffix = this.recorder.mimeType
          .split(';')[0]
          .replace('audio/', '')

        const blob = new Blob(this.chunks, { type: this.recorder.mimeType })
        const filename = `${new Date().toISOString()}.${suffix}`
        this.file = new File([blob], filename, {
          lastModified: new Date(),
          type: blob.type
        })
        this.file.icon = audioFileIcon

        this.url = URL.createObjectURL(this.file)
        this.chunks = []
      }
    },

    async start () {
      const constraints = { audio: true }
      findMic()

      try {
        this.stream = await navigator.mediaDevices.getUserMedia(constraints)

        if (this.stream) {
          this.recorder = new MediaRecorder(this.stream)
          this.recorder.start()

          this.recorder.addEventListener('stop', () => {
            if (this.stream) {
              this.stream.getAudioTracks()[0].stop()
            }

            this.handleChunks()
            this.recording = false
          })

          this.recorder.addEventListener('dataavailable', evt => {
            this.chunks.push(evt.data)
          })

          this.recording = true
        }
      } catch (err) {
        this.createNotification({
          msg: err.message,
          type: 'error'
        })
      }
    },

    stop () {
      if (this.recorder && this.recorder.state === 'recording') {
        this.recorder.stop()
      }
    },

    reset () {
      this.stop()

      setTimeout(() => {
        this.recorder = null
        this.stream = null
        this.file = null
        this.url = null
      }, 0)
    },

    close () {
      this.reset()
      this.dialog = false
    },

    save () {
      if (!this.file) {
        return
      }

      this.$emit('save', this.file)
      this.close()
    },

    async register () {
      if (!window.MediaRecorder) {
        const audioRecorderPolyfill = await import(/* webpackChunkName: "audio-recorder-polyfill" */ 'audio-recorder-polyfill')
        window.MediaRecorder = audioRecorderPolyfill.default

        if (MediaRecorder.notSupported) {
          this.supported = false
        }
      }
    },

    unregister () {
      this.reset()
    }
  }
}
</script>

<style lang="scss">
.pulse {
  animation: 2s map-get($transition, 'ease-in-out') 0s infinite pulse;

  &:hover {
    animation: none;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 $shadow-key-umbra-opacity;
  }

  50% {
    box-shadow: 0 0 0 1rem $shadow-key-ambient-opacity;
  }

  100% {
    box-shadow: 0 0 0 0 $shadow-key-umbra-opacity;
  }
}
</style>
