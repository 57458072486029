<template>
  <VListItem
    v-if="meeting && meeting.id"
    title="На урок назначено мероприятие"
    dense
    :to="{ name: 'meeting', params: { meetingID: meeting.id }}"
    target="_blank"
    rel="noopener noreferrer"
  >
    <VListItemIcon>
      <VIcon>event</VIcon>
    </VListItemIcon>
    <VListItemContent>
      <VListItemTitle>{{ meeting.title }}</VListItemTitle>
      <VListItemSubtitle>
        {{ prepareDates(meeting.starts_at, meeting.ends_at) }}
      </VListItemSubtitle>
    </VListItemContent>
  </VListItem>
</template>

<script>

import { UDate } from '@/utils/date'

export default {
  name: 'TrainingLecture',

  props: {
    meeting: {
      type: Object,
      default: () => {}
    }
  },

  methods: {
    prepareDates (meetingStart, meetingEnd) {
      const start = new UDate(meetingStart).valueOf()
      const end = new UDate(meetingEnd).valueOf()

      let str = ''
      // …С <date> по <date>
      if (start === end) {
        str += ` ${UDate.formatDateTime(start)}`
        return str
      }
      if (start) {
        str += ` ${this.$t('from.one').toLowerCase()} ${UDate.formatDateTime(start)}`
      }

      if (end) {
        str += ` ${this.$t('to.one').toLowerCase()} ${UDate.formatDateTime(end - UDate.minuteInSeconds)}`
      }

      return str
    }
  }
}
</script>
