<template>
  <VList
    class="session-steps"
    :dense="dense"
  >
    <div
      v-for="grouppedStep in grouppedSteps.values()"
      :key="grouppedStep.id"
    >
      <VSubheader
        v-if="grouppedSteps.size > 1"
        class="pl-2 text-uppercase"
        inset
      >
        {{ grouppedStep.title }}
      </VSubheader>

      <VListItem
        v-for="step in grouppedStep.steps"
        :key="step.id"
        class="session-steps__item"
        :disabled="!step.is_started && !step.is_prev_required_step_accepted"
        :to="{ name: 'training', params: { sessionID: step.session_id, stepID: step.id }}"
      >
        <VListItemIcon>
          <StepStatus
            :status="step.status"
            :disabled="!step.is_started && !step.is_prev_required_step_accepted"
            only-icon
          />
        </VListItemIcon>
        <VListItemContent>
          <div>{{ step.title }}</div>
          <VListItemSubtitle>
            {{ $t('$app.lesson_type.one') }}: {{ $t(`${step.lesson_type_info.code_plural}.one`) }}
          </VListItemSubtitle>
          <VListItemSubtitle
            v-if="step.is_summative"
            class="primary--text"
          >
            {{ $t('summative_lessons.one') }}
          </VListItemSubtitle>
          <VListItemSubtitle
            v-else-if="step.is_required"
            class="warning--text"
          >
            {{ $t('required.one') }}
          </VListItemSubtitle>
          <VListItemSubtitle
            v-if="step.is_exercise"
            class="success--text"
          >
            {{ $t('exercise.one') }}
          </VListItemSubtitle>
          <VListItemSubtitle
            v-if="!preview && (step._starts_at || step._ends_at)"
            class="error--text"
          >
            {{ getTimeLimits(step._starts_at, step._ends_at) }}
          </VListItemSubtitle>

          <VListItemSubtitle
            v-if="!preview && step.meeting?.id"
          >
            На урок назначено мероприятие:
            <RouterLink
              :to="{ name: 'meeting', params: { meetingID: step.meeting.id }}"
              target="_blank"
              rel="noopener noreferrer"
              @click.native.stop
            >
              {{ step.meeting.title }}
            </RouterLink> ({{ step.meeting.status.title.toLowerCase() }})
          </VListItemSubtitle>
          <template
            v-if="$vuetify.breakpoint.xs && !preview && step.lesson_type_info.code !== 'scorm'"
          >
            <VListItemSubtitle
              v-if="step.attempts && step.attempts[0].status?.code === 'rejected'"
              class="error--text"
            >
              {{ step.attempts[0].status.title }}
            </VListItemSubtitle>

            <VListItemSubtitle
              v-else-if="step.mark && step.is_evaluated"
              :class="step.is_accepted ? 'success--text' : 'error--text'"
            >
              {{ $t("marks.one") }}: {{ step.mark }}
            </VListItemSubtitle>

            <VListItemSubtitle
              v-else-if="step.is_completed"
              class="primary--text"
            >
              {{ $t("step_status_completed.one") }}
            </VListItemSubtitle>
          </template>
        </VListItemContent>
        <VListItemAction v-if="!$vuetify.breakpoint.xs && !preview && step.lesson_type_info.code !== 'scorm'">
          <VListItemSubtitle
            v-if="step.attempts && step.attempts[0].status?.code === 'rejected'"
            class="error--text"
          >
            {{ step.attempts[0].status.title }}
          </VListItemSubtitle>

          <VListItemActionText
            v-else-if="step.mark && step.is_evaluated"
            :class="['body-2', step.is_accepted ? 'success--text' : 'error--text']"
          >
            {{ $t("marks.one") }}: {{ step.mark }}
          </VListItemActionText>

          <VListItemActionText
            v-else-if="step.is_completed"
            class="body-2 primary--text"
          >
            {{ $t("step_status_completed.one") }}
          </VListItemActionText>
        </VListItemAction>
      </VListItem>
    </div>
  </VList>
</template>

<script>
import { UDate } from '@/utils/date'

import StepStatus from '@/components/StepStatus.vue'

export default {
  name: 'SessionSteps',

  components: {
    StepStatus
  },

  props: {
    value: {
      type: Array,
      required: true
    },

    dense: {
      type: Boolean,
      default: false
    },

    preview: {
      type: Boolean,
      default: false
    },

  },

  computed: {
    steps () {
      const prepareStep = (function () {
        let isPrevRequiredStepAccepted = true

        return function (step) {
          step.is_prev_required_step_accepted = isPrevRequiredStepAccepted

          if (step.is_required) {
            if (!step.is_accepted && !(step.settings.deferrable && step.is_completed)) {
              isPrevRequiredStepAccepted = false
            }
          }

          if (step?.attempts?.length) {
            if (step.attempts[0].is_evaluated) {
              step.mark = step.attempts[0].mark || '0 %'
              step.is_evaluated = true
            }

            step.is_completed = step.attempts[0].is_completed
          }
          step._starts_at = new UDate(step.starts_at).valueOf()
          step._ends_at = new UDate(step.ends_at).valueOf()

          return step
        }
      })()

      return (this.value || []).map(prepareStep)
    },

    grouppedSteps () {
      return this.steps.reduce((acc, currentValue, idx) => {
        if (acc.has(currentValue.course_id)) {
          acc.get(currentValue.course_id).steps.push(currentValue)
        } else {
          acc.set(currentValue.course_id, {
            title: currentValue.course_title,
            id: idx,
            steps: [currentValue]
          })
        }

        return acc
      }, new Map())
    }
  },

  methods: {
    getTimeLimits (start, end) {
      let str = this.$t('available.one')

      // …c <date> по <date>
      if (start) {
        str += ` ${this.$t('from.one').toLowerCase()} ${UDate.formatDateTime(start)}`
      }

      if (end) {
        str += ` ${this.$t('to.one').toLowerCase()} ${UDate.formatDateTime(end - UDate.minuteInSeconds)}`
      }

      return str
    },
  },
}
</script>

<style lang="scss">
.session-steps {
  .v-list-item__icon {
    z-index: 1;
  }

  &.v-list--dense {
    font-size: map-deep-get($headings, 'subtitle-2', 'size');
  }
}

.session-steps__item {
  position: relative;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    top: $spacer * 4;
    left: 2rem;
    transform: translate(-50%, 0);
    height: 100%;
    width: 2px;
    background: map-get($material-light, 'dividers');
  }
}
</style>
