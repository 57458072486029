class BaseScormAPI {
  constructor (events) {
    this.data = {}

    this.onLmsInitialize = events.onLmsInitialize || ((data) => data)
    this.onLmsSetValue = events.onLmsSetValue || (() => {})
    this.onLmsCommit = events.onLmsCommit || (() => {})
  }

  // Begins a communication session with the LMS.
  // Initialize(""): bool
  lmsInitialize () {
    // eslint-disable-next-line
    console.log('SCORM2004API.Initialize()')
    this.data = this.onLmsInitialize(this.data)
    return 'true'
  }

  // Ends a communication session with the LMS.
  // Terminate(""): bool
  lmsFinish () {
    // eslint-disable-next-line
    console.log('SCORM2004API.Terminate()')
    return 'true'
  }

  // Retrieves a value from the LMS.
  // GetValue(element: CMIElement): string
  lmsGetValue (element) {
    const value = this.data[element]
    return (value !== undefined) ? value : ''
  }

  // Saves a value to the LMS.
  // SetValue(element: CMIElement, value: string): string
  lmsSetValue (element, value) {
    this.data[element] = value
    this.onLmsSetValue(this.data)

    return value
  }

  // Indicates to the LMS that all data should be persisted (not required).
  // LMSCommit(""): bool
  lmsCommit () {
    // eslint-disable-next-line
    console.log('SCORM12API.LMSCommit()')
    // eslint-disable-next-line
    console.log(this.data)
    this.onLmsCommit(this.data)

    return 'true'
  }

  // Returns the error code that resulted from the last API call.
  // LMSGetLastError(): CMIErrorCode
  lmsGetLastError () {
    return '0'
  }

  // Returns a short string describing the specified
  // LMSGetErrorString(errorCode: CMIErrorCode): string
  lmsGetErrorString () {
    return ''
  }

  // Returns detailed information about the last error that occurred.
  // LMSGetDiagnostic(errorCode: CMIErrorCode): string
  lmsGetDiagnostic () {
    return ''
  }
}

export class Scorm2004API extends BaseScormAPI {
  constructor (account = {}, events = {}) {
    super(events)

    this.Initialize = super.lmsInitialize
    this.Terminate = super.lmsFinish
    this.GetValue = super.lmsGetValue
    this.SetValue = super.lmsSetValue
    this.Commit = super.lmsCommit
    this.GetLastError = super.lmsGetLastError
    this.GetErrorString = super.lmsGetErrorString
    this.GetDiagnostic = super.lmsGetDiagnostic

    // @see https://scorm.com/scorm-explained/technical-scorm/run-time/run-time-reference/
    this.data = {
      // Represents the version of the data model
      // (characterstring, RO)
      'cmi._version': '0',

      // Identifies the learner on behalf of whom the SCO was launched
      // (long_identifier_type (SPM: 4000), RO)
      'cmi.learner_id': account.uuid,

      // Name provided for the learner by the LMS
      // (localized_string_type (SPM: 250), RO)
      'cmi.learner_name': account.title,

      // The learner’s current location in the SCO
      // (characterstring (SPM: 1000), RW)
      'cmi.location': '',

      // Indicates whether the learner has completed the SCO
      // ("completed", "incomplete", "not attempted", "unknown", RW)
      'cmi.completion_status': 'unknown',

      // Indicates whether the learner will be credited for performance in the SCO
      // ("credit", "no-credit", RO)
      'cmi.credit': 'credit',

      // Measure of the progress the learner has made toward completing the SCO
      // (real (10,7) range (0..1), RW)
      'cmi.progress_measure': '',

      // Listing of supported data model elements
      // (scaled,raw,min,max, RO)
      'cmi.score._children': 'scaled,raw,min,max',

      // Number that reflects the performance of the learner
      // (real (10,7) range (-1..1), RW)
      'cmi.score.scaled': '',

      // Number that reflects the performance of the learner relative to the range bounded by the values of min and max
      // (real (10,7), RW)
      'cmi.score.raw': '',

      // Minimum value in the range for the raw score
      // (real (10,7), RW)
      'cmi.score.min': '',

      // Maximum value in the range for the raw score
      // (real (10,7), RW)
      'cmi.score.max': '',

      // Amount of time that the learner has spent in the current learner session for this SCO
      // (timeinterval (second,10,2), WO)
      'cmi.session_time': '',

      // Indicates whether the learner has mastered the SCO
      // ("passed", "failed", "unknown", RW)
      'cmi.success_status': ''
    }
  }

  attach (win) {
    win.API_1484_11 = this
  }

  destroy (win) {
    win.API_1484_11 = undefined
  }
}

export class Scorm12API extends BaseScormAPI {
  constructor (account = {}, events = {}) {
    super(events)

    this.LMSInitialize = super.lmsInitialize
    this.LMSFinish = super.lmsFinish
    this.LMSGetValue = super.lmsGetValue
    this.LMSSetValue = super.lmsSetValue
    this.LMSCommit = super.lmsCommit
    this.LMSGetLastError = super.lmsGetLastError
    this.LMSGetErrorString = super.lmsGetErrorString
    this.LMSGetDiagnostic = super.lmsGetDiagnostic

    // @see https://scorm.com/scorm-explained/technical-scorm/run-time/run-time-reference/
    this.data = {
    // Listing of supported data model elements
    // (student_id, student_name, lesson_location, credit, lesson_status, entry, score, total_time, lesson_mode, exit, session_time, RO)
      'cmi.core._children': 'student_id,student_name,lesson_location,credit,lesson_status,score,session_time',

      // Identifies the student on behalf of whom the SCO was launched
      // (CMIString (SPM: 255), RO)
      'cmi.core.student_id': account.uuid,

      // Name provided for the student by the LMS
      // (CMIString (SPM: 255), RO)
      'cmi.core.student_name': account.title,

      // The learner’s current location in the SCO
      // (CMIString (SPM: 255), RW)
      'cmi.core.lesson_location': '',

      // Indicates whether the learner will be credited for performance in the SCO
      // ("credit", "no-credit", RO)
      'cmi.core.credit': 'credit',

      // Indicates whether the learner has completed and satisfied the requirements for the SCO
      // ("passed", "completed", "failed", "incomplete", "browsed", "not attempted", RW)
      'cmi.core.lesson_status': '',

      // Listing of supported data model elements
      // (raw,min,max, RO)
      'cmi.core.score_children': 'raw,min,max',

      // Number that reflects the performance of the learner relative to the range bounded by the values of min and max
      // (CMIDecimal, RW)
      'cmi.core.score.raw': '',

      // Minimum value in the range for the raw score
      // (CMIDecimal, RW)
      'cmi.core.score.min': '',

      // Maximum value in the range for the raw score
      // (CMIDecimal, RW)
      'cmi.core.score.max': '',

      // Amount of time that the learner has spent in the current learner session for this SCO
      // (CMITimespan, WO)
      'cmi.core.session_time': ''
    }
  }

  attach (win) {
    win.API = this
  }

  destroy (win) {
    win.API = undefined
  }
}

export class H5PAPI {
  constructor (onmessage) {
    this.onmessage = onmessage || (() => {})
  }

  handleMessage = (evt) => {
    if (evt.data?.context === 'h5p') {
      // eslint-disable-next-line
      console.log('< h5p', JSON.stringify(evt.data))
      this.onmessage(evt)
    }
  }

  attach (win) {
    // Поймаем сообщение H5P-виджета.
    win.addEventListener('message', this.handleMessage)
  }

  destroy (win) {
    win.removeEventListener('message', this.handleMessage)
  }
}
