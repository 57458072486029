<template>
  <VMenu offset-y>
    <template #activator="{ on, attrs }">
      <VBtn
        text
        outlined
        v-bind="attrs"
        v-on="on"
      >
        <VIcon left>
          print
        </VIcon>
        {{ $t('print.one') }} {{ $t('results.other') }}
      </VBtn>
    </template>

    <VList dense>
      <VListItem
        v-if="html"
        :href="html"
        target="_blank"
        rel="noopener noreferrer"
      >
        <VListItemContent>
          <VListItemTitle>HTML</VListItemTitle>
        </VListItemContent>
      </VListItem>
      <VDivider />
      <VListItem
        v-if="pdf && vendor.branding && vendor.branding.can_generate_pdf"
        :href="pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        <VListItemContent>
          <VListItemTitle>PDF</VListItemTitle>
        </VListItemContent>
      </VListItem>
      <VDivider />
      <VListItem
        v-if="odt && vendor.branding && vendor.branding.can_generate_odt"
        :href="odt"
        target="_blank"
        rel="noopener noreferrer"
      >
        <VListItemContent>
          <VListItemTitle>ODT</VListItemTitle>
        </VListItemContent>
      </VListItem>
    </VList>
  </VMenu>
</template>

<script>
import { mapGetters } from 'vuex'

import * as getters from '@/store/getters/types'

export default {
  name: 'UPrintResults',

  props: {
    html: {
      type: String,
      default: ''
    },

    pdf: {
      type: String,
      default: ''
    },

    odt: {
      type: String,
      default: ''
    }
  },

  computed: mapGetters({
    vendor: getters.VENDOR
  })
}
</script>
