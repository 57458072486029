<template>
  <VToolbar
    dense
    flat
    tag="div"
  >
    <VToolbarTitle>
      <StepStatus :status="step.status" />
    </VToolbarTitle>
    <VSpacer />

    <VBtn
      icon
      :title="isFullView ? 'Свернуть' : $t('show_in_fullscreen.one')"
      @click="toggleView(!isFullView)"
    >
      <VIcon>{{ isFullView ? 'close_fullscreen' : 'open_in_full' }}</VIcon>
    </VBtn>
  </VToolbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import StepStatus from '@/components/StepStatus.vue'
import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'

export default {
  name: 'StepToolbar',
  components: {
    StepStatus
  },

  props: {
    step: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      isFullView: getters.IS_FULL_VIEW
    })
  },

  created () {
    this.register()
  },

  methods: {
    ...mapActions({
      toggleView: actions.TOGGLE_VIEW
    }),

    register () {
      this.toggleView(JSON.parse(localStorage.getItem('isFullView')))
    }
  }
}
</script>
