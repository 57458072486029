<template>
  <div
    v-if="length >= 7"
    :class="['paginator', classes]"
  >
    <button
      :class="['nav-button', { disabled: currentPage === 1 }]"
      :disabled="currentPage === 1"
      @click="previousPage"
    >
      <VIcon>chevron_left</VIcon>
    </button>

    <VTextField
      :value="currentInputPage"
      type="text"
      hide-details
      single-line
      outlined
      class="page-input"
      :suffix="' из ' + length"
      :style="inputStyle"
      dense
      @change="handleInput"
    />

    <button
      :class="['nav-button', { disabled: currentPage === length }]"
      :disabled="currentPage === length"
      @click="nextPage"
    >
      <VIcon>chevron_right</VIcon>
    </button>
  </div>

  <VPagination
    v-else
    v-model="currentInputPage"
    :class="classes"
    :total-visible="totalVisible"
    :length="length"
    @input="onInputPageChange"
  />
</template>

<script>
export default {
  name: 'UPaginator',
  props: {
    value: {
      type: Number,
      required: true
    },
    length: {
      type: Number,
      required: true
    },
    classes: {
      type: String,
      default: ''
    },
    totalVisible: {
      type: Number,
      default: 5,
    }
  },

  data () {
    return {
      currentInputPage: this.value,
      lastValidValue: this.value,
    }
  },

  computed: {
    currentPage () {
      return this.value
    },

    inputStyle () {
      const length = String(this.length).length
      return {
        width: `${(length || 1) * 10 + 60}px`
      }
    }
  },
  watch: {
    value (newValue) {
      this.currentInputPage = Number(newValue)
    }
  },

  methods: {
    handleInput (value) {
      if (value === '') {
        this.currentInputPage = this.lastValidValue
        return
      }

      const number = Number(value)
      if (!isNaN(number) && number >= 1 && number <= this.length) {
        this.lastValidValue = number
        this.$emit('input', number)
      } else {
        this.$emit('input', this.lastValidValue)
      }
    },

    range (start, end) {
      return Array.from({ length: end - start + 1 }, (_, i) => start + i)
    },
    previousPage () {
      if (this.currentPage > 1) {
        this.$emit('input', this.currentPage - 1)
      }
    },
    nextPage () {
      if (this.currentPage < this.length) {
        this.$emit('input', this.currentPage + 1)
      }
    },
    onInputPageChange (value) {
      const number = Number(value)
      if (number >= 1 && number <= this.length) {
        this.currentInputPage = number
        this.$emit('input', this.currentInputPage)
      } else {
        this.currentInputPage = this.currentPage
      }
    },
  }
}
</script>

<style scoped>
.paginator {
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-item {
  cursor: pointer;

  box-shadow: 0px 2px 4px -1px rgba(94, 99, 104, 0.1), 0px 4px 5px 0px rgba(94, 99, 104, 0.1), 0px 1px 10px 0px rgba(94, 99, 104, 0.1);
  border-radius: 4px;
  font-size: 1rem;
  height: 34px;
  margin: 0.3rem;
  min-width: 34px;
  padding: 0 5px;
  text-decoration: none;
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
  width: auto;
}
.page-item.active {
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: white;
}
.nav-button {
  background: #FFFFFF;
  box-shadow: 0px 3px 1px -2px rgba(94, 99, 104, 0.1), 0px 2px 2px 0px rgba(94, 99, 104, 0.1), 0px 1px 5px 0px rgba(94, 99, 104, 0.1);
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 32px;
  width: 32px;
  margin: 0.3rem 10px;

  cursor: pointer;
}
.nav-button.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.page-input {
  width: 80px;
  text-align: center;
  flex: none;
  margin: 0 1rem;
}

.page-input input {
  text-align: right;
}
</style>
