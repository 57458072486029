<template>
  <div v-if="value && value.length">
    <VRow v-if="mode === 'cards'">
      <VCol
        v-for="(file, idx) in value"
        :key="idx"
        cols="auto"
      >
        <VCard :width="100">
          <VListItem>
            <VListItemContent>
              <VListItemTitle>{{ file.name }}</VListItemTitle>
            </VListItemContent>
          </VListItem>
          <VImg
            :src="file.icon || file.uri"
            :aspect-ratio="1"
            :alt="file.name"
          />
          <VBtn
            v-file:remove="{files: value, idx: idx}"
            :title="$t('delete.one')"
            block
            text
            color="error"
          >
            {{ $t('delete.one') }}
          </VBtn>
        </VCard>
      </VCol>
    </VRow>

    <VList v-else>
      <VListItem
        v-for="(file, idx) in value"
        :key="idx"
      >
        <VListItemAvatar tile>
          <VImg
            :src="file.icon || file.uri"
            :aspect-ratio="1"
            alt=""
          />
        </VListItemAvatar>
        <VListItemContent>
          <VListItemTitle>{{ file.name }}</VListItemTitle>
        </VListItemContent>
        <VListItemAction>
          <VBtn
            v-file:remove="{files: value, idx: idx}"
            :title="$t('delete.one')"
            icon
          >
            <VIcon>delete</VIcon>
          </VBtn>
        </VListItemAction>
      </VListItem>
    </VList>
  </div>
</template>

<script>
export default {
  name: 'FileList',
  props: {
    value: {
      type: Array,
      default: () => []
    },

    mode: {
      type: String,
      default: 'list'
    }
  }
}
</script>
